import React from "react";
import styles from "./CommunityHome.module.css";
import CommunityCard from "../../Components/Community/CommunityCard";
import applications from "../../assets/Community/applications.svg";
import members from "../../assets/Community/members.svg";
import specialMembers from "../../assets/Community/special-members.svg";
import msgs from "../../assets/Community/msgs.svg";
import settings from "../../assets/Community/settings.svg";

const CommunityHome = () => {
  const blocks = [
    {
      id: 1,
      icon: applications,
      name: "Applications",
      color: "#106A94",
      to: "/community/applications",
    },
    {
      id: 2,
      icon: members,
      name: "Community Members",
      color: "#307389",
      to: "/community/members",
    },
    {
      id: 3,
      icon: specialMembers,
      name: "Special Members",
      color: "#1789BE",
      to: "/community/special-members",
    },
    { id: 4, icon: msgs, name: "Send Messages", color: "#BB7C46", to: "" },
    {
      id: 5,
      icon: settings,
      name: "Community Settings",
      color: "#1789BE",
      to: "/community/settings",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>The Founders Community</h2>
        {blocks.map((block) => (
          <CommunityCard
            key={block.id}
            color={block.color}
            icon={block.icon}
            name={block.name}
            to={block.to}
          />
        ))}
      </div>
    </div>
  );
};

export default CommunityHome;
