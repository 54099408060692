import React, { useEffect, useState } from "react";
import styles from "./Graph.module.css";
import { get } from "../../fetch";

const Graph = () => {
  const [time, setTime] = useState("monthly");
  const [selectedOption, setSelectedOption] = useState("applications");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [graphBars, setGraphBars] = useState([]);

  const barHeight = (index) => {
    const highestNumber = Math.max(...graphBars.map((item) => item.number));
    const percentage = (graphBars[index].number / highestNumber) * 80;
    return `${percentage}%`;
  };

  useEffect(() => {
    get(`dashboard/graph/${selectedOption}/?interval=${time}`)
      .then((response) => {
        let startIndex = 0;
        while (
          startIndex < response.length &&
          response[startIndex].number === 0
        ) {
          startIndex++;
        }
        const filteredResponse = response.slice(startIndex);
        setGraphBars(filteredResponse);
        // setGraphBars(response);
      })
      .catch((error) => {});
  }, [selectedOption, time]);

  return (
    <div className={styles.graphContainer}>
      <div className={styles.time}>
        <div
          onClick={() => setTime("weekly")}
          className={
            time === "weekly" ? styles.timeBtnSelected : styles.timeBtn
          }
        >
          Week
        </div>
        <div
          onClick={() => setTime("monthly")}
          className={
            time === "monthly" ? styles.timeBtnSelected : styles.timeBtn
          }
        >
          Monthly
        </div>
        <div
          onClick={() => setTime("yearly")}
          className={
            time === "yearly" ? styles.timeBtnSelected : styles.timeBtn
          }
        >
          Yearly
        </div>
        <div className={styles.menu} onClick={() => setIsMenuOpen(true)} />
        {isMenuOpen && (
          <div className={styles.menuOptions}>
            <div
              className={`${styles.menuOption} ${
                selectedOption === "applications" && styles.selectedOption
              }`}
              onClick={() => {
                setSelectedOption("applications");
                setIsMenuOpen(false);
              }}
            >
              Applications
            </div>
            <div
              className={`${styles.menuOption} ${
                selectedOption === "transactions" && styles.selectedOption
              }`}
              onClick={() => {
                setSelectedOption("transactions");
                setIsMenuOpen(false);
              }}
            >
              Transactions
            </div>
          </div>
        )}
      </div>
      <div className={styles.graphContent}>
        {graphBars.map((graphBar, index) => (
          <>
            <div className={styles.barContainer} onClick={() => {}}>
              <div
                style={{ height: barHeight(index) }}
                className={`${styles.bar} ${
                  index % 2 === 1 ? styles.darkBar : styles.lightBar
                }`}
              >{graphBar.number}</div>
              {time !== "weekly" && <p
                className={
                  index % 2 === 1 ? styles.darkBarTxt : styles.lightBarTxt
                }
              >
                {graphBar.text}
              </p>}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Graph;
