import React, { useContext, useEffect, useState } from "react";
import styles from "./DashboardHome.module.css";
import Container from "../../Components/Account/Container";
import DashboardCard from "../../Components/Dashboard/DashboardCard";
import { useNavigate } from "react-router-dom";

import applicationIcon from "../../assets/Dashboard/application-icon.svg";
import cliqIcon from "../../assets/Dashboard/cliq-icon.svg";
import bankIcon from "../../assets/Dashboard/bank-icon.svg";

import { get } from "../../fetch";
import Graph from "../../Components/Dashboard/Graph";
import Context from "../../Context";

const DashboardHome = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(Context);

  const [transactions, setTransactions] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loadingT, setLoadingT] = useState(true);
  const [loadingA, setLoadingA] = useState(true);

  useEffect(() => {
    setLoading(true);
    get("home-transactions")
      .then((response) => {
        setLoading(false);
        setTransactions(response);
        setLoadingT(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading]);
  
  useEffect(() => {
    setLoading(true);
    get("home-applications")
      .then((response) => {
        setLoading(false);
        setApplications(response);
        setLoadingA(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setLoading]);

  if (!loadingT && !loadingA)
    return (
      <Container columns={2}>
        <Graph />
        <div className={styles.content}>
          <div className={styles.cardsHeader}>
            <p className={styles.cardsTitle}>Latest Transactions</p>
            <button
              className={styles.seeAll}
              onClick={() => navigate("/dashboard/transactions")}
            >
              See All
            </button>
          </div>
          {transactions.map((item) => (
            <DashboardCard
              key={item.id}
              img={item.type === "cliq" ? cliqIcon : bankIcon}
              title={item.user.full_name}
              status={item.status}
              onClick={() => navigate(`/dashboard/transactions/${item.id}`)}
            />
          ))}
          <div className={styles.cardsHeader}>
            <p className={styles.cardsTitle}>Latest Applications</p>
            <button
              className={styles.seeAll}
              onClick={() => navigate("/dashboard/applications")}
            >
              See All
            </button>
          </div>
          {applications.map((item) => (
            <DashboardCard
              img={applicationIcon}
              key={item.id}
              title={item.full_name}
              status={item.status}
              onClick={() => navigate(`/dashboard/applications/${item.id}`)}
            />
          ))}
        </div>
      </Container>
    );
  else return <div></div>;
};

export default DashboardHome;
