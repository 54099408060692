import React, { useContext, useState } from "react";
import styles from "./ResetPassword.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import password from "../../assets/Auth/password.svg";
import AuthText from "../../Components/TextComponents/AuthText";
import { useNavigate } from "react-router-dom";
import { put } from "../../fetch";
import Context from "../../Context";

const ResetPassword = () => {
  const { setLoading } = useContext(Context);
  const navigate = useNavigate();
  const [data, setData] = useState({
    password: {
      text: "",
      error: false,
    },
    passwordRepeat: {
      text: "",
      error: false,
    },
  });

  const setPassword = (password) => {
    setData({
      ...data,
      password: {
        text: password,
        error: false,
      },
    });
  };

  const setPasswordRepeat = (password) => {
    setData({
      ...data,
      passwordRepeat: {
        text: password,
        error: false,
      },
    });
  };

  const submit = (e) => {
    e.preventDefault();
    if (data.password.text === "") {
      setData({
        ...data,
        password: {
          text: "",
          error: true,
        },
      });
    } else if (data.passwordRepeat.text !== data.password.text) {
      setData({
        ...data,
        passwordRepeat: {
          text: "",
          error: true,
        },
      });
    } else {
      setLoading(true);
      put("change-password", {
        password: data.password.text,
        password_confirmation: data.passwordRepeat.text,
      })
        .then(() => {
          setLoading(false);
          alert("Password changed successfully. You can login with your new password now.")
          navigate(-1);
        })
        .catch(() => {
          setLoading(false);
          alert("Looks like there's a problem. Reload the page and if this happens again, please come back later.");
        });
    }
  };

  return (
    <Container>
      <img className={styles.cube} src={cube} alt="cube" />
      <h2 className={styles.title}>Update Password</h2>
      <form onSubmit={submit}>
        <AuthText
          type="password"
          placeholder="New password"
          value={data.password.text}
          error={data.password.error}
          onChange={(e) => setPassword(e.target.value)}
          img={password}
        />
        <AuthText
          type="password"
          placeholder="Confirm new password"
          value={data.passwordRepeat.text}
          error={data.passwordRepeat.error}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          img={password}
        />
        <button className={styles.loginBtn} type="submit">
          Submit
        </button>
      </form>
    </Container>
  );
};

export default ResetPassword;
