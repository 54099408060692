import React, { useContext, useEffect, useState } from "react";
import styles from "./Application.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../../fetch";

import page1 from "../../assets/Dashboard/page1.svg";
import page2 from "../../assets/Dashboard/page2.svg";
import page3 from "../../assets/Dashboard/page3.svg";

import verify from "../../assets/Account/verify.svg";
import unverify from "../../assets/Account/unverify.svg";
import freemonths from "../../assets/Account/freemonths.svg";
import Context from "../../Context";

const Application = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const { setLoading } = useContext(Context);

  const accept = () => {
    setLoading(true);
    put(`confirm-application/${id}`)
      .then((response) => {
        setLoading(false);
        setData(response);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const reject = () => {
    setLoading(true);
    put(`decline-application/${id}`)
      .then(() => {
        setLoading(false);
        setData({ ...data, status: "Declined" });
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    get(`applications/${id}`)
      .then((r) => {
        setLoading(false);
        setData(r);
      })
      .catch((e) => {
        setLoading(false);
        alert("Looks like there's a problem. Reload the page and if this happens again, please come back later.");
      });
  }, [id, setLoading]);

  const back = () => {
    if (page === 1) {
      navigate("/dashboard/applications");
    } else {
      window.scrollTo(0, 0);
      setPage(page - 1);
    }
  };

  const next = () => {
    window.scrollTo(0, 0);
    setPage(page + 1);
  };

  const verifyUser = () => {
    setLoading(true);
    put(`verify/${data.user_id}`)
      .then(() => {
        setLoading(false);
        setData({ ...data, verified: true });
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const unverifyUser = () => {
    setLoading(true);
    put(`unverify/${data.user_id}`)
      .then(() => {
        setLoading(false);
        setData({ ...data, verified: false });
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <img className={styles.cube} src={cube} alt="cube" />
      <p className={styles.name}>{data.full_name}</p>

      {page === 1 && <img className={styles.pages} src={page1} alt="" />}
      {page === 2 && <img className={styles.pages} src={page2} alt="" />}
      {page === 3 && <img className={styles.pages} src={page3} alt="" />}

      {page === 1 && (
        <>
          <p className={styles.section}>*Personal Information:</p>
          <div className={styles.inputContainer}>
            <label>Full name</label>
            <input value={data.full_name} disabled />
          </div>
          <div className={styles.inputContainer}>
            <label>Email</label>
            <input value={data.email} disabled />
          </div>
          <div className={styles.inputContainer}>
            <label>Phone</label>
            <input value={data.phone} disabled />
          </div>
          <div className={styles.inputContainer}>
            <label>Website</label>
            <input value={data.url} disabled />
          </div>

          <p className={styles.section}>*Entrepreneurial Background:</p>
          <div className={styles.inputContainer}>
            <label>Years of entrepreneurial experience</label>
            <input value={data.years_of_experience} disabled />
          </div>
          <div className={styles.inputContainer}>
            <label>Tell us more about your business</label>
            <textarea value={data.business_outline} disabled />
          </div>
        </>
      )}

      {page === 2 && (
        <>
          <p className={styles.section}>*Qualifications and Achievements:</p>
          <div className={styles.inputContainer}>
            <label>Educational Background</label>
            <input value={data.educational_background} disabled />
          </div>
          <div className={styles.inputContainer}>
            <label>Professional Affiliations</label>
            <input value={data.professional_affiliations} disabled />
          </div>
          <div className={styles.inputContainer}>
            <label>Mention three strengths you have</label>
            <textarea value={data.strengths} disabled />
          </div>
          <p className={styles.section}>*Commitment to the Community:</p>
          <div className={styles.inputContainer}>
            <label>Why do you want to join?</label>
            <textarea value={data.reasons_to_join} disabled />
          </div>
        </>
      )}

      {page === 3 && (
        <>
          <p className={styles.section}>*Additional Information:</p>
          <div className={styles.inputContainer}>
            <label>Referred By</label>
            <input value={data.referred_by} disabled />
          </div>
          <h4 style={{ marginBottom: 0 }}>
            User applied at:{" "}
            {new Date(data.created_at)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                // hour12: true,
              })
              .toUpperCase()}
          </h4>
          {data.status !== "Pending" && <h4 style={{ marginTop: 3 }}>
            Application {data.status === "Confirmed" ? "confirmed" : "declined"} at:{" "}
            {new Date(data.updated_at)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                // hour12: true,
              })
              .toUpperCase()}
          </h4>}
        </>
      )}

      {page !== 3 ? (
        <div className={styles.btnsContainer}>
          <button className={`${styles.btn} ${styles.back}`} onClick={back}>
            {page === 1 ? "Cancel" : "Back"}
          </button>
          <button className={`${styles.btn} ${styles.next}`} onClick={next}>
            Next
          </button>
        </div>
      ) : (
        <div className={styles.btnsP3Container}>
          {data.status !== "Confirmed" && (
            <button
              className={`${styles.btnP3} ${styles.accept}`}
              onClick={accept}
            >
              Accept
            </button>
          )}
          {data.status === "Pending" && (
            <button
              className={`${styles.btnP3} ${styles.reject}`}
              onClick={reject}
            >
              Reject
            </button>
          )}
          {data.status === "Confirmed" && (
            <>
              <button
                className={`${styles.btnP3} ${styles.freeMonths}`}
                onClick={() =>
                  navigate(`/community/free-months/${data.user_id}`)
                }
              >
                <img
                  className={styles.buttonImg}
                  src={freemonths}
                  alt="free months"
                />
                Free Months
              </button>
              <button
                className={`${styles.btnP3} ${
                  data.verified ? styles.unverify : styles.verify
                }`}
                onClick={data.verified ? unverifyUser : verifyUser}
              >
                <img
                  className={styles.buttonImg}
                  src={data.verified ? unverify : verify}
                  alt={data.verified ? "unverify" : "verify"}
                />
                {data.verified ? "Unverify" : "Verify"}
              </button>
            </>
          )}
          <button className={`${styles.btnP3} ${styles.backP3}`} onClick={back}>
            Back
          </button>
        </div>
      )}
    </Container>
  );
};

export default Application;
