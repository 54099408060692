import React from "react";
import styles from "./RegularInput.module.css";

const RegularInput = ({ placeholder, type, value, onChange }) => {
  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RegularInput;
