import React, { useContext, useEffect, useState } from "react";
import styles from "./Packages.module.css";
import Container from "../../Components/Account/Container";
import packages from "../../assets/Community/packagesLarge.svg";
import PackageCard from "../../Components/Community/PackageCard";
import { get } from "../../fetch";
import { useNavigate } from "react-router-dom";
import Context from "../../Context";

const Packages = () => {
  const [packs, setPacks] = useState([]);
  const navigate = useNavigate();
  const {setLoading} = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get("packages").then((response) => {
      setPacks(response);
      setLoading(false);
    }).catch(() => {
      alert("There seems to be a problem");
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <Container>
      <img className={styles.img} src={packages} alt="packages" />
      <h2 className={styles.title}>Community Packages</h2>
      {packs.map((pack) => <PackageCard key={pack.id} id={pack.id} title={pack.name} months={pack.months} price={pack.price} star={pack.star} />)}
      <button onClick={() => navigate("/community/settings/modify-packages/new")} className={styles.button}>New Package</button>
    </Container>
  );
};

export default Packages;
