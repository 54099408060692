import React, { useContext, useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import Search from "../../Components/TextComponents/Search";
import DashboardCard from "../../Components/Dashboard/DashboardCard";
import { get } from "../../fetch";
import { useNavigate } from "react-router-dom";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";
import cliqIcon from "../../assets/Dashboard/cliq-icon.svg";
import bankIcon from "../../assets/Dashboard/bank-icon.svg";
import Context from "../../Context";

const Transactions = ({ data }) => {
  const navigate = useNavigate();
  const { setLoading } = useContext(Context);

  const [items, setItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);

  const onSearch = (option, searchText) => {
    const filteredItems =
      data === "transactions"
        ? items.filter(
            (item) =>
              (option.toLowerCase() === "all" || item.status.toLowerCase() === option.toLowerCase()) &&
              (item.user.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
              item.type.toLowerCase().includes(searchText.toLowerCase()))
          )
        : items.filter(
            (item) =>
              (option.toLowerCase() === "all" || item.status.toLowerCase() === option.toLowerCase()) &&
              item.full_name.toLowerCase().includes(searchText.toLowerCase())
          );
  
    setDisplayedItems(filteredItems);
  };
  

  useEffect(() => {
    setLoading(true);
    get(data).then((response) => {
      setItems(response);
      setDisplayedItems(response);
      setLoading(false);
    });
  }, [data, setLoading]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          {data === "transactions"
            ? "Transactions History"
            : "Community Applications"}
        </h2>
        <Search onSearch={onSearch} members={false} />
        <p className={styles.resultsN}>{`--- (${displayedItems.length}) results ---`}</p>
        {data === "transactions" ? (
          <>
            {displayedItems.map((item) => (
              <DashboardCard
                img={item.type === "cliq" ? cliqIcon : bankIcon}
                key={item.id}
                title={item.user.full_name}
                status={item.status}
                onClick={() => navigate(`/dashboard/transactions/${item.id}`)}
              />
            ))}
          </>
        ) : (
          <>
            {displayedItems.map((item) => (
              <DashboardCard
                img={applicationIcon}
                key={item.id}
                title={item.full_name}
                status={item.status}
                onClick={() => navigate(`/dashboard/applications/${item.id}`)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Transactions;
