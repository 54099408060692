import React from "react";
import styles from "./DashboardCard.module.css";

import pending from "../../assets/Dashboard/pending.svg";
import confirmed from "../../assets/Dashboard/confirmed.svg";
import declined from "../../assets/Dashboard/declined.svg";

const DashboardCard = ({ title="", status="", onClick, img="" }) => {
  return (
    <div
      className={styles.container}
      onClick={onClick}
    >
      <div className={styles.content}>
        <img className={styles.img} src={img} alt={title} />
        <div className={styles.txtContainer}>
          <p className={styles.name}>{title}</p>
          <p className={styles.status}>{status}</p>
        </div>
      </div>
      {status === "Pending" && (
        <img className={styles.checkmark} src={pending} alt="pending" />
      )}
      {status === "Confirmed" && (
        <img className={styles.checkmark} src={confirmed} alt="confirmed" />
      )}
      {status === "Declined" && (
        <img className={styles.checkmark} src={declined} alt="declined" />
      )}
    </div>
  );
};

export default DashboardCard;
