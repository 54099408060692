import React, { useState, useEffect, useContext } from "react";
import styles from "./Payment.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import TopPlaceholder from "../../Components/TextComponents/TopPlaceholder";
import { useNavigate } from "react-router-dom";
import { get, put } from "../../fetch";
import Context from "../../Context";

const Payment = () => {
  const navigate = useNavigate();
  const [accountDetails, setAccountDetails] = useState({});
  const [load, setLoad] = useState(false);
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get("configs")
      .then((response) => {
        setLoading(false);
        setAccountDetails(response);
        setLoad(true);
      })
      .catch(() => {
        setLoading(false);
        alert("There seems to be a problem");
      });
  }, [setLoading]);

  const submit = () => {
    setLoading(true);
    put("update-configs", accountDetails)
      .then(() => {
        setLoading(false);
        navigate(-1);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  if (load)
    return (
      <Container>
        <img className={styles.cube} src={cube} alt="cube" />
        <h2 className={styles.title}>Payment Details</h2>
        <p className={styles.subText}>* Account Details:</p>
        <TopPlaceholder
          type="text"
          placeholder="Name:"
          value={accountDetails.bank_account_owner_name}
          onChange={(e) =>
            setAccountDetails({
              ...accountDetails,
              bank_account_owner_name: e.target.value,
            })
          }
        />
        <TopPlaceholder
          type="text"
          placeholder="Bank:"
          value={accountDetails.bank_name}
          onChange={(e) =>
            setAccountDetails({
              ...accountDetails,
              bank_name: e.target.value,
            })
          }
        />
        <TopPlaceholder
          type="text"
          placeholder="ACC Number:"
          value={accountDetails.bank_account_number}
          onChange={(e) =>
            setAccountDetails({
              ...accountDetails,
              bank_account_number: e.target.value,
            })
          }
        />
        <TopPlaceholder
          type="text"
          placeholder="IBAN:"
          value={accountDetails.bank_account_iban}
          onChange={(e) =>
            setAccountDetails({
              ...accountDetails,
              bank_account_iban: e.target.value,
            })
          }
        />
        <TopPlaceholder
          type="text"
          placeholder="CLIQ:"
          value={accountDetails.bank_account_cliq}
          onChange={(e) =>
            setAccountDetails({
              ...accountDetails,
              bank_account_cliq: e.target.value,
            })
          }
        />
        <button className={styles.save} onClick={submit}>
          Save
        </button>
      </Container>
    );
  else return <></>;
};

export default Payment;
