import React from "react";
import styles from "./MembersCard.module.css";
import { useNavigate } from "react-router-dom";
import blue from "../../assets/Community/blue-tick.svg";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";
import redDot from "../../assets/Community/redDot.png";

const MembersCard = ({ member }) => {
  const navigate = useNavigate();

  const isSubscriptionEndingSoon = () => {
    // Get today's date
    const today = new Date();

    // Get the subscription end date from the member object
    const subscriptionEndDate = new Date(member.subscription_ends_at);

    // Calculate the difference in milliseconds
    const timeDifference = subscriptionEndDate - today;

    // Calculate the difference in days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Check if the subscription ends within the next 30 days
    return daysDifference <= 30;
  };

  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/community/member/${member.id}`)}
    >
      <div className={styles.content}>
        <img
          className={styles.img}
          src={
            member.profile_pic ? member.profile_pic.original : applicationIcon
          }
          alt={member.full_name}
        />
        <div className={styles.txtContainer}>
          <p className={styles.name}>
            {member.full_name}{" "}
            {!member.active && !member.special_member && (
              <img alt="" style={{ backgroundColor: "red" }} className={styles.statusDot} />
            )}
            {member.id !== 1 && !member.special_member && isSubscriptionEndingSoon() && (
              <img alt="" style={{ backgroundColor: "#FFD700" }} className={styles.statusDot} />
            )}
          </p>
          <p className={styles.industry}>{member.industry}</p>
        </div>
      </div>
      {member.verified ? (
        <img className={styles.checkmark} src={blue} alt="blue tick" />
      ) : (
        ""
      )}
    </div>
  );
};

export default MembersCard;
