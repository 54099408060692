import React from "react";
import styles from "./Block.module.css";
import { useNavigate } from "react-router-dom";

const Block = ({ block }) => {
  const navigate = useNavigate();
  const openURL = (url) => {
    window.open(`https://${url}`, "_blank");
  }
  return (
    <div
      className={`${styles.block} ${
        block.disabled ? styles.disabled : styles.enabled
      }`}
      key={block.id}
      onClick={() => {
        block.to && navigate(block.to);
        block.url && openURL(block.url);
      }}
    >
      {block.disabled && <p className={styles.soon}>SOON</p>}
      <img className={styles.img} src={block.icon} alt={block.name} />
      <p className={styles.name}>{block.name}</p>
    </div>
  );
};

export default Block;
