import React from "react";
import styles from "./TopPlaceholder.module.css";

const TopPlaceholder = ({ placeholder, type, value, onChange }) => {
  return (
    <div className={styles.container}>
      <p className={styles.placeholder}>{placeholder}</p>
      <input
        className={styles.input}
        type={type}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TopPlaceholder;
