import React, { useContext, useEffect, useState, useRef } from "react";
import Container from "../../Components/Account/Container";
import styles from "./NewSpecialMember.module.css";
import TextIconOutside from "../../Components/TextComponents/TextIconOutside";
import phone from "../../assets/Account/phone.svg";
import email from "../../assets/Community/email.svg";
import website from "../../assets/Account/website.svg";
import position from "../../assets/Account/position.svg";
import country from "../../assets/Account/country.svg";
import { useNavigate } from "react-router-dom";
import Context from "../../Context";
import { post } from "../../fetch";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";
import axios from "axios";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropperDialog from "../../Components/CropperDialog";
import { countriesArr } from "../../countries";

const NewSpecialMember = () => {
  const [profile, setProfile] = useState({});
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const { setLoading } = useContext(Context);
  const [selectedProfilePic, setSelectedProfilePic] = useState(null);
  const [cropperData, setCropperData] = useState(null);
  const [displayedProfilePic, setDisplayedProfilePic] = useState(null);
  const businessCategories = [
    "Accounting",
    "AI Apps",
    "Architecture",
    "AR/VR in Architecture",
    "Artificial Intelligence",
    "Automotive",
    "Beauty Tech",
    "Biotechnology",
    "Blockchain",
    "Childcare Service",
    "Children Services",
    "Clean Energy",
    "Clean Water Technology",
    "Clothing & Apparel",
    "Coaching",
    "Construction",
    "Consulting",
    "Consumer Electronics",
    "Copywriting",
    "Cybersecurity",
    "Dentistry",
    "Desserts",
    "Digital Marketing",
    "Digital Publishing",
    "E-commerce",
    "Education",
    "Edutainment",
    "Elderly Care",
    "Electric Vehicles",
    "Entertainment",
    "Environmental Services",
    "Event Planning",
    "Eye Wear",
    "Fashion and Apparel",
    "Finance and Banking",
    "Financial Technology (FinTech)",
    "Fitness and Wellness",
    "Food and Beverage",
    "Footwear",
    "Gaming",
    "Health and Beauty",
    "Healthcare",
    "Home Automation",
    "Home Improvement",
    "Home Appliances",
    "Homewares",
    "Hospitality",
    "Human Resources",
    "Interior Design",
    "Internet of Things (IoT)",
    "Landscaping",
    "Language Learning",
    "Legal Services",
    "Logistics and Supply Chain",
    "Manufacturing",
    "Marketing and Advertising",
    "Medical Devices",
    "Media Production",
    "Nanotechnology",
    "Nonprofit and Social Services",
    "Online Learning",
    "Outdoor Recreation",
    "Personal Development",
    "Personal Finance",
    "Pet Care",
    "Pharmaceuticals",
    "Photography",
    "Professional Services",
    "Real Estate",
    "Renewable Energy",
    "Robotics",
    "Social Impact",
    "Social Media",
    "Software Development",
    "Sports and Fitness",
    "Subscription Services",
    "Sustainable Agriculture",
    "Sustainable Fashion",
    "Technology Services",
    "Telecommunications",
    "Telemedicine",
    "Travel and Tourism",
    "Virtual Reality",
    "Video Production",
    "Wearable Technology",
    "Web Development",
  ];
  const imgRef = useRef();

  const [isCropperDialogOpen, setCropperDialogOpen] = useState(false);
  const cropperRef = useRef();

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  };

  const onCropSubmit = () => {
    if (selectedProfilePic === null) {
      return;
    }
    const cropper = cropperRef.current?.cropper;
    const dataUrl = cropper.getCroppedCanvas().toDataURL();
    setCropperData(dataUrl);
    setCropperDialogOpen(false);
    if (dataUrl !== null) {
      const file = dataURItoBlob(dataUrl);
      setSelectedProfilePic(file);
    }
  };

  const onProfilePicChange = (e) => {
    let files = e.target.files;
    if (files.length === 0) {
      return;
    }

    setSelectedProfilePic(files[0]);
    setCropperDialogOpen(true);
  };
  useEffect(() => {
    if (selectedProfilePic !== null) {
      if (cropperData !== null) {
        setDisplayedProfilePic(cropperData);
      } else {
        setDisplayedProfilePic(URL.createObjectURL(selectedProfilePic));
      }
    } else if (profile.profile_pic) {
      setDisplayedProfilePic(profile.profile_pic.original);
    } else {
      setDisplayedProfilePic(applicationIcon);
    }
  }, [selectedProfilePic, profile]);

  // const onProfilePicChange = (e) => {
  //   let files = e.target.files;
  //   if (files.length === 0) {
  //     return;
  //   }

  //   let image = files[0];

  //   let data = new FormData();
  //   data.set("profile_picture", image);

  //   setLoading(true);
  //   post('profile-picture', data)
  //     .then(() => {
  //       window.location.reload();
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    setProfile({
      full_name: "",
      bio: "",
      email: "",
      phone_number: "",
      url: "",
      industry: "",
    });
  }, []);

  const cleanURL = (url) => {
    let cleanedURL = url.replace(/(^\w+:|^)\/\//, "").replace("www.", "");
    cleanedURL = cleanedURL.replace(/\/+$/, "");
    return cleanedURL;
  };

  useEffect(() => {
    const countryData = countriesArr.map((country) => country.name.common);
    setCountries(countryData.sort((a, b) => a.localeCompare(b)));
  }, []);

  const submit = () => {
    if (
      profile.full_name !== "" &&
      profile.bio.length >= 300 &&
      profile.email !== "" &&
      profile.phone_number !== "" &&
      profile.industry !== ""
    ) {
      // setLoading(true);
      post("special-members", { ...profile, url: cleanURL(profile.url) })
        .then((response) => {
          if (selectedProfilePic) {
            let data = new FormData();
            data.set("profile_picture", selectedProfilePic);

            setLoading(true);
            post(`special-member/profile-picture/${response}`, data)
              .then(() => {
                navigate(-1);
                setLoading(false);
              })
              .catch((e) => {
                console.log(e);
                setLoading(false);
              });
          } else {
            navigate(-1);
            setLoading(false);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
          setLoading(false);
        });
    } else {
      alert("Please fill all fields");
    }
  };

  return (
    <Container>
      <input
        type="file"
        style={{ display: "none" }}
        ref={imgRef}
        accept="image/*"
        onChange={onProfilePicChange}
      />
      <CropperDialog
        isOpen={isCropperDialogOpen}
        setOpen={setCropperDialogOpen}
        maxWidth='600px'
        title={''}
        padding='10px 15px'
        persistant
        child={
          <>
            {
              selectedProfilePic !== null ?
              <Cropper
                src={URL.createObjectURL(selectedProfilePic)}
                style={{ height: 400, width: "100%" }}
                // Cropper.js options
                initialAspectRatio={1}
                aspectRatio={1}
                guides={false}
                ref={cropperRef}
              /> : <></>
            }
          </>
        }
        actions={
          <button className={styles.save} onClick={onCropSubmit}>
            Save
          </button>
        }
      />
      <div
        className={styles.profilePicture}
        onClick={() => imgRef.current.click()}
      >
        <img
          src={displayedProfilePic}
          alt="profile pic"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            pointerEvents: "none",
            objectFit: "cover",
          }}
        />
        <div className={styles.editPicture}></div>
      </div>
      <div className={styles.userInfo}>
        <input
          className={styles.nameInput}
          placeholder="Name"
          value={profile.full_name}
          onChange={(e) =>
            setProfile({ ...profile, full_name: e.target.value })
          }
        />
        <textarea
          placeholder={`Write a brief description about this member and what they do. You could also add their social media links here for other members to connect with them.`}
          className={styles.bioTextarea}
          value={profile.bio}
          onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
        />
        <p className={styles.taCount}>{profile.bio ? profile.bio.length : 0}</p>
        <TextIconOutside
          placeholder="Email"
          type="email"
          value={profile.email}
          icon={email}
          onChange={(email) => setProfile({ ...profile, email })}
        />
        <TextIconOutside
          select
          options={countries}
          value={profile.country}
          icon={country}
          onChange={(country) => setProfile({ ...profile, country })}
          placeholder="Country"
        />
        <TextIconOutside
          placeholder="Mobile Number"
          type="phone"
          value={profile.phone_number}
          icon={phone}
          onChange={(phone_number) => setProfile({ ...profile, phone_number })}
        />
        <TextIconOutside
          placeholder="Website (Optional)"
          type="url"
          value={profile.url}
          icon={website}
          onChange={(url) => setProfile({ ...profile, url })}
        />
        <TextIconOutside
          select
          value={profile.industry}
          icon={position}
          onChange={(industry) => setProfile({ ...profile, industry })}
          options={businessCategories}
          placeholder="Industry"
        />
        <button className={styles.save} onClick={submit}>
          Add Special Member
        </button>
      </div>
    </Container>
  );
};

export default NewSpecialMember;
