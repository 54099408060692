import React, { useContext, useEffect, useState } from "react";
import styles from "./Transaction.module.css";
import Container from "../../Components/Account/Container";
import { useNavigate, useParams } from "react-router-dom";
import UserInfoItem from "../../Components/Lists/UserInfoItem";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";

import doc from "../../assets/Dashboard/doc.svg";
import handshake from "../../assets/Dashboard/handshake.svg";
import phone from "../../assets/Dashboard/phone.svg";

import subscription from "../../assets/Dashboard/subscription.svg";
import pack from "../../assets/Dashboard/package.svg";
import payment from "../../assets/Dashboard/payment.svg";
import pending from "../../assets/Dashboard/pending2.svg";
import confirmed from "../../assets/Dashboard/confirmed2.svg";
import declined from "../../assets/Dashboard/declined2.svg";
import time from "../../assets/Account/time.svg";

import download from "../../assets/Dashboard/download.svg";

import { get, put } from "../../fetch";
import Context from "../../Context";

const Transaction = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(Context);

  const { id } = useParams();
  const [item, setItem] = useState({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoading(true);
    get(`transactions/${id}`)
      .then((response) => {
        setLoading(false);
        setItem(response);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [id, setLoading]);

  useEffect(() => {
    if (item.id) setLoad(true);
  }, [item]);

  const getStatusImg = () => {
    if (item.status === "Pending") {
      return pending;
    } else if (item.status === "Confirmed") {
      return confirmed;
    } else {
      return declined;
    }
  };

  const confirm = () => {
    setLoading(true);
    put(`confirm-transaction/${id}`)
      .then(() => {
        setLoading(false);
        setItem({ ...item, status: "Confirmed" });
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const decline = () => {
    setLoading(true);
    put(`decline-transaction/${id}`)
      .then(() => {
        setLoading(false);
        setItem({ ...item, status: "Declined" });
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const downloadScreenshot = () => {
    let win = window.open(item.image_url, "_blank");
    win.focus();
  };

  const phoneCall = () => {
    window.location.href = `tel:${item.user.phone_number}`;
  };

  if (load)
    return (
      <Container>
        <img
          className={styles.img}
          src={item.user.profile_pic ? item.user.profile_pic.original : applicationIcon}
          alt={item.user.full_name}
        />
        <div className={styles.name}>{item.user.full_name}</div>
        <p className={styles.email}>{item.user.email}</p>
        <div>
          <div className={styles.btnIconContainer}>
            <img
              className={styles.btnIcon}
              src={doc}
              alt="doc"
              onClick={() =>
                navigate(`/dashboard/applications/${item.user.application_id}`)
              }
            />
            <img
              className={styles.btnIcon}
              src={handshake}
              alt="handshake"
              onClick={() => navigate(`/community/member/${item.user_id}`)}
            />
            <img className={styles.btnIcon} src={phone} alt="phone" onClick={phoneCall} />
          </div>
        </div>
        <UserInfoItem
          item={{ img: subscription, text: "Community Subscription", unclickable: true }}
        />
        <UserInfoItem
          item={{ img: pack, text: item.package.name + " Package", unclickable: true }}
        />
        <UserInfoItem
          item={{
            img: payment,
            text: item.package.price + " JD (" + item.type.toUpperCase() + ")",
            unclickable: true
          }}
        />
        <UserInfoItem
          item={{
            img: time,
            text: new Date(item.created_at).toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }).toUpperCase(),
            unclickable: true
          }}
        />
        <UserInfoItem item={{ img: getStatusImg(), text: item.status, unclickable: true }} />
        <div className={styles.btnsContainer}>
          <button className={styles.screenshot} onClick={downloadScreenshot}>
            <img style={{ marginRight: 10 }} src={download} alt="download" />
            Download Screenshot
          </button>
          {item.status === "Pending" && (
            <>
              <button
                className={`${styles.cdBtns} ${styles.confirm}`}
                onClick={confirm}
              >
                Confirm
              </button>
              <button
                className={`${styles.cdBtns} ${styles.decline}`}
                onClick={decline}
              >
                Decline
              </button>
            </>
          )}
        </div>
      </Container>
    );
  else return <></>;
};

export default Transaction;
