import React, { useContext, useEffect, useState, useRef } from "react";
import Container from "../../Components/Account/Container";
import styles from "./EditProfile.module.css";
// import sari from "../../assets/temp/sari.svg";
import TextIconOutside from "../../Components/TextComponents/TextIconOutside";
import phone from "../../assets/Account/phone.svg";
import email from "../../assets/Community/email.svg";
import website from "../../assets/Account/website.svg";
import position from "../../assets/Account/position.svg";
import country from "../../assets/Account/country.svg";
import { useNavigate, useParams } from "react-router-dom";
import Context from "../../Context";
import { get, put, post } from "../../fetch";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";
import axios from "axios";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropperDialog from "../../Components/CropperDialog";
import { countriesArr } from '../../countries';

const EditProfile = ({ special = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedProfilePic, setSelectedProfilePic] = useState(null);
  const [cropperData, setCropperData] = useState(null);
  const [displayedProfilePic, setDisplayedProfilePic] = useState(null);
  const [profile, setProfile] = useState({});
  const [showBioError, setShowBioError] = useState(false);
  const [countries, setCountries] = useState([]);
  const { user, setLoading } = useContext(Context);
  const businessCategories = [
    "Accounting",
    "AI Apps",
    "Architecture",
    "AR/VR in Architecture",
    "Artificial Intelligence",
    "Automotive",
    "Beauty Tech",
    "Biotechnology",
    "Blockchain",
    "Childcare Service",
    "Children Services",
    "Clean Energy",
    "Clean Water Technology",
    "Clothing & Apparel",
    "Coaching",
    "Construction",
    "Consulting",
    "Consumer Electronics",
    "Copywriting",
    "Cybersecurity",
    "Dentistry",
    "Desserts",
    "Digital Marketing",
    "Digital Publishing",
    "E-commerce",
    "Education",
    "Edutainment",
    "Elderly Care",
    "Electric Vehicles",
    "Entertainment",
    "Environmental Services",
    "Event Planning",
    "Eye Wear",
    "Fashion and Apparel",
    "Finance and Banking",
    "Financial Technology (FinTech)",
    "Fitness and Wellness",
    "Food and Beverage",
    "Footwear",
    "Gaming",
    "Health and Beauty",
    "Healthcare",
    "Home Automation",
    "Home Improvement",
    "Home Appliances",
    "Homewares",
    "Hospitality",
    "Human Resources",
    "Interior Design",
    "Internet of Things (IoT)",
    "Landscaping",
    "Language Learning",
    "Legal Services",
    "Logistics and Supply Chain",
    "Manufacturing",
    "Marketing and Advertising",
    "Medical Devices",
    "Media Production",
    "Nanotechnology",
    "Nonprofit and Social Services",
    "Online Learning",
    "Outdoor Recreation",
    "Personal Development",
    "Personal Finance",
    "Pet Care",
    "Pharmaceuticals",
    "Photography",
    "Professional Services",
    "Real Estate",
    "Renewable Energy",
    "Robotics",
    "Social Impact",
    "Social Media",
    "Software Development",
    "Sports and Fitness",
    "Subscription Services",
    "Sustainable Agriculture",
    "Sustainable Fashion",
    "Technology Services",
    "Telecommunications",
    "Telemedicine",
    "Travel and Tourism",
    "Virtual Reality",
    "Video Production",
    "Wearable Technology",
    "Web Development",
  ];

  const imgRef = useRef();

  const [isCropperDialogOpen, setCropperDialogOpen] = useState(false);
  const cropperRef = useRef();

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  const onCropSubmit = () => {
    if (selectedProfilePic === null) {
      return;
    }
    const cropper = cropperRef.current?.cropper;
    const dataUrl = cropper.getCroppedCanvas().toDataURL();
    setCropperData(dataUrl);
    setCropperDialogOpen(false);
    if (dataUrl !== null) {
      const file = dataURItoBlob(dataUrl);
      setSelectedProfilePic(file);
    }
  };

  const onProfilePicChange = (e) => {
    let files = e.target.files;
    if (files.length === 0) {
      return;
    }

    setSelectedProfilePic(files[0]);
    setCropperDialogOpen(true);
  };
  useEffect(() => {
    if (selectedProfilePic !== null) {
      if (cropperData !== null) {
        setDisplayedProfilePic(cropperData);
      } else {
        setDisplayedProfilePic(URL.createObjectURL(selectedProfilePic));
      }
    } else if (profile.profile_pic) {
      setDisplayedProfilePic(profile.profile_pic.original);
    } else {
      setDisplayedProfilePic(applicationIcon);
    }
  }, [selectedProfilePic, profile]);

  const cleanURL = (url) => {
    let cleanedURL = url.replace(/(^\w+:|^)\/\//, "").replace("www.", "");
    cleanedURL = cleanedURL.replace(/\/+$/, "");
    return cleanedURL;
  };

  useEffect(() => {
    if (special) {
      setLoading(true);
      get(`members/${id}`).then((response) => {
        setLoading(false);
        setProfile({
          profile_pic: response.profile_pic || "",
          full_name: response.full_name || "",
          email: response.email || "",
          bio: response.bio || "",
          phone_number: response.phone_number || "",
          country: response.country || "",
          url: response.url || "",
          industry: response.industry || "",
        });
      });
    } else {
      setProfile({
        profile_pic: user.profile_pic || "",
        full_name: user.full_name || "",
        email: user.email || "",
        bio: user.bio || "",
        phone_number: user.phone_number || "",
        country: user.country || "",
        url: user.url || "",
        industry: user.industry || "",
      });
    }
  }, [special, id, user]);

  useEffect(() => {
    const countryData = countriesArr.map((country) => country.name.common);
    setCountries(countryData.sort((a, b) => a.localeCompare(b)));
  }, []);

  const submit = () => {
    if (
      profile.full_name !== "" &&
      profile.email !== "" &&
      profile.bio.length >= 300 &&
      profile.phone_number !== "" &&
      profile.country !== "" &&
      profile.industry !== ""
    ) {
      setLoading(true);
      if (special) {
        put(`special-members/${id}`, { ...profile, url: cleanURL(profile.url) })
          .then(() => {
            if (selectedProfilePic) {
              let data = new FormData();
              data.set("profile_picture", selectedProfilePic);
              post(`special-member/profile-picture/${id}`, data)
                .then(() => {
                  alert("Profile updated");
                  navigate(-1);
                  setLoading(false);
                })
                .catch((e) => {
                  window.location.reload();
                  setLoading(false);
                });
            } else {
              navigate(-1);
              setLoading(false);
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
            setLoading(false);
          });
      } else {
        put("user", { ...profile, url: cleanURL(profile.url) })
          .then(() => {
            if (selectedProfilePic) {
              let data = new FormData();
              data.set("profile_picture", selectedProfilePic);
              post(`profile-picture`, data)
                .then(() => {
                  alert("Profile updated");
                  window.location.reload();
                  setLoading(false);
                })
                .catch(() => {
                  window.location.reload();
                  setLoading(false);
                });
            } else {
              alert("Profile updated");
              window.location.reload();
              setLoading(false);
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
            setLoading(false);
          });
      }
    } else {
      alert("Make sure you've filled all fields correctly");
      profile.bio.length < 300 && setShowBioError(true);
    }
  };

  return (
    <Container>
      <input
        type="file"
        style={{ display: "none" }}
        ref={imgRef}
        accept="image/*"
        onChange={onProfilePicChange}
      />
      <CropperDialog
        isOpen={isCropperDialogOpen}
        setOpen={setCropperDialogOpen}
        maxWidth='600px'
        title={''}
        padding='10px 15px'
        persistant
        child={
          <>
            {
              selectedProfilePic !== null ?
                <Cropper
                  src={URL.createObjectURL(selectedProfilePic)}
                  style={{ height: 400, width: "100%" }}
                  // Cropper.js options
                  initialAspectRatio={1}
                  aspectRatio={1}
                  guides={false}
                  ref={cropperRef}
                /> : <></>
            }
          </>
        }
        actions={
          <button className={styles.save} onClick={onCropSubmit}>
            Save
          </button>
        }
      />
      <div
        className={styles.profilePicture}
        onClick={() => imgRef.current.click()}
      >
        <img
          src={displayedProfilePic}
          alt="profile pic"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            pointerEvents: "none",
            objectFit: "cover",
          }}
        />
        <div className={styles.editPicture}></div>
      </div>
      <div className={styles.userInfo}>
        <input
          className={styles.nameInput}
          placeholder="Name"
          value={profile.full_name}
          onChange={(e) =>
            setProfile({ ...profile, full_name: e.target.value })
          }
        />
        <p className={styles.email}>{!special ? profile.email : ""}</p>

        {showBioError && (
          <span className={styles.errorSpan}>
            *This field must be no less than 300 characters long
          </span>
        )}
        <textarea
          placeholder={
            special
              ? "Write a brief description about this member and what they do. You could also add their social media links here for other members to connect with them."
              : `Write a brief description about who you are and what you do. You could also add your social media links here for other members to connect with you.`
          }
          className={styles.bioTextarea}
          value={profile.bio}
          onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
        />
        <p className={styles.taCount}>{profile.bio ? profile.bio.length : 0}</p>
        <TextIconOutside
          type="email"
          value={profile.email}
          icon={email}
          onChange={(email) => setProfile({ ...profile, email })}
          placeholder="Phone Number"
        />
        <TextIconOutside
          type="phone"
          value={profile.phone_number}
          icon={phone}
          onChange={(phone_number) => setProfile({ ...profile, phone_number })}
          placeholder="Phone Number"
        />
        <TextIconOutside
          select
          options={countries}
          value={profile.country}
          icon={country}
          onChange={(country) => setProfile({ ...profile, country })}
          placeholder="Country"
        />
        <TextIconOutside
          type="text"
          value={profile.url}
          icon={website}
          onChange={(url) => setProfile({ ...profile, url })}
          placeholder="Website (Optional)"
        />
        <TextIconOutside
          select
          value={profile.industry}
          icon={position}
          onChange={(industry) => setProfile({ ...profile, industry })}
          options={businessCategories}
          placeholder="Industry"
        />
        <button className={styles.save} onClick={submit}>
          Save
        </button>
      </div>
    </Container>
  );
};

export default EditProfile;
