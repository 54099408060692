import React from "react";
import styles from "./CommunitySettings.module.css";
import CommunityCard from "../../Components/Community/CommunityCard";
import Container from "../../Components/Account/Container"
import cube from "../../assets/Account/cube.png";

import modifyPackages from "../../assets/Community/modify-packages.svg";
import whatsapp from "../../assets/Community/whatsapp-link.svg";

const CommunitySettings = () => {
  const blocks = [
    { id: 1, icon: modifyPackages, name: "Modify Packages", color: "#BB7C46", to: "/community/settings/modify-packages" },
    { id: 2, icon: whatsapp, name: "Whatsapp Link", color: "#1789BE", to: "/community/settings/whatsapp" }
  ];
  return (
    <Container>
      <img className={styles.cube} src={cube} alt="cube" />
      <h2 className={styles.title}>Community Settings</h2>
      {blocks.map((block) => (
        <CommunityCard
          key={block.id}
          color={block.color}
          icon={block.icon}
          name={block.name}
          to={block.to}
        />
      ))}
    </Container>
  );
};

export default CommunitySettings;
