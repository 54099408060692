import React from "react";
import styles from "./PackageCard.module.css";
import { useNavigate } from "react-router-dom";
import goldenStar from "../../assets/Community/golden-star.svg";

const PackageCard = ({ id, title, price, months, star }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <p className={styles.title}>{title}</p>
        <div className={styles.content}>
          <div className={styles.detailsContainer}>
            <p className={styles.details}>{price} JD</p>
            <p className={styles.details}>{months} Months</p>
          </div>
          {star ? <img className={styles.star} src={goldenStar} alt="star" /> : ""}
        </div>
      </div>
      <button
        className={styles.edit}
        onClick={() => navigate(`/community/settings/modify-packages/${id}`)}
      />
    </div>
  );
};

export default PackageCard;
