import React, { useContext, useEffect, useState } from "react";
import styles from "./Member.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../Components/Account/Container";
import UserInfoItem from "../../Components/Lists/UserInfoItem";
import phone from "../../assets/Account/phone.svg";
import website from "../../assets/Account/website.svg";
import time from "../../assets/Account/time.svg";
import position from "../../assets/Account/position.svg";
import country from "../../assets/Account/country.svg";
import blue from "../../assets/Community/blue-tick.svg";
import verifyIcon from "../../assets/Account/verify.svg";
import unverifyIcon from "../../assets/Account/unverify.svg";
import freemonths from "../../assets/Account/freemonths.svg";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";

import { get, put, remove } from "../../fetch";
import Context from "../../Context";
import ReactLinkify from "react-linkify";

const Member = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [member, setMember] = useState(null);
  const [load, setLoad] = useState(false);
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get(`members/${id}`)
      .then((response) => {
        setMember(response);
        setLoad(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [id, setLoading]);

  const verify = () => {
    setLoading(true);
    put(`verify/${id}`)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const unverify = () => {
    setLoading(true);
    put(`unverify/${id}`)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const kickout = () => {
    setLoading(true);
    remove(`kickout/${id}`)
      .then(() => {
        navigate(-1);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const removeSpecialMember = () => {
    setLoading(true);
    remove(`special-members/${id}`)
      .then(() => {
        setLoading(false);
        navigate(-1);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const phoneCall = () => {
    window.location.href = `tel:${member.phone_number}`;
  };

  const openURL = () => {
    window.open(`https://${member.url}`, "_blank");
  };

  if (load)
    return (
      <Container>
        <img
          className={styles.img}
          src={
            member.profile_pic ? member.profile_pic.original : applicationIcon
          }
          alt={member.name}
        />
        <div className={styles.name}>
          {member.full_name}
          {member.verified ? (
            <img className={styles.verified} src={blue} alt="verified" />
          ) : (
            ""
          )}
        </div>
        <p className={styles.email}>{member.email}</p>
        <p className={styles.bio}>
          {" "}
          {member.bio &&
            member.bio.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                <ReactLinkify
                  componentDecorator={(href, text, key) => (
                    <a
                      href={href}
                      key={key}
                      target="_blank" // Open link in a new tab
                      rel="noopener noreferrer"
                      style={{ color: "your_desired_color" }} // Change the text color
                    >
                      {text}
                    </a>
                  )}
                >
                  {line}
                </ReactLinkify>
                <br />
              </React.Fragment>
            ))}
        </p>
        {member.phone_number && (
          <UserInfoItem
            item={{
              text: member.phone_number,
              img: phone,
            }}
            onClick={phoneCall}
          />
        )}
        {member.url && (
          <UserInfoItem
            item={{ text: member.url, img: website }}
            onClick={openURL}
          />
        )}
        {member.country && (
          <UserInfoItem
            item={{ text: member.country, img: country, unclickable: true }}
          />
        )}
        {member.industry && (
          <UserInfoItem
            item={{ text: member.industry, img: position, unclickable: true }}
          />
        )}
        {member.user_type !== "admin" && (
          <>
            {member.active ? (
              <UserInfoItem
                item={{
                  text:
                    "Active Until: " +
                    new Date(member.subscription_ends_at)
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                      .toUpperCase(),
                  img: time,
                  unclickable: true,
                }}
              />
            ) : (
              <>
                {!member.special_member && (
                  <UserInfoItem
                    item={{
                      text: "Not active yet",
                      img: time,
                      unclickable: true,
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
        {member.user_type !== "admin" && (
          <>
            {member.special_member ? (
              <>
                <button
                  className={`${styles.button} ${styles.editMember}`}
                  onClick={() => navigate(`/edit-special-member/${id}`)}
                >
                  Edit Special Member
                </button>
                <button
                  className={`${styles.button} ${styles.removeMember}`}
                  onClick={removeSpecialMember}
                >
                  Remove Special Member
                </button>
              </>
            ) : (
              <>
                <button
                  className={`${styles.button} ${
                    member.verified ? styles.unverify : styles.verify
                  }`}
                  onClick={() => {
                    setMember({ ...member, verified: !member.verified });
                    if (member.verified) unverify();
                    else verify();
                  }}
                >
                  <img
                    className={styles.buttonImg}
                    src={member.verified ? unverifyIcon : verifyIcon}
                    alt={member.verified ? "unverify" : "verify"}
                  />
                  {member.verified ? "Unverify" : "Verify"}
                </button>
                <button
                  className={`${styles.button} ${styles.freeMonths}`}
                  onClick={() => navigate(`/community/free-months/${id}`)}
                >
                  <img
                    className={styles.buttonImg}
                    src={freemonths}
                    alt="free months"
                  />
                  Free Months
                </button>
                <button
                  className={`${styles.button} ${styles.kickout}`}
                  onClick={kickout}
                >
                  Kickout
                </button>
              </>
            )}
          </>
        )}
      </Container>
    );
  else return <></>;
};

export default Member;
