import React, { useContext, useEffect, useState } from "react";
import styles from "./Package.module.css";
import Container from "../../Components/Account/Container";
import packages from "../../assets/Community/packagesLarge.svg";
import { useNavigate, useParams } from "react-router-dom";
import price from "../../assets/Community/price-icon.svg";
import months from "../../assets/Community/months-icon.svg";
import star from "../../assets/Community/star-icon.svg";
import { get, post, put, remove } from "../../fetch";
import Context from "../../Context";
import RegularInput from "../../Components/TextComponents/RegularInput";

const Package = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pack, setPack] = useState({});
  const { setLoading } = useContext(Context);

  useEffect(() => {
    if (id) {
      get(`packages/${id}`)
        .then((response) => {
          setLoading(false);

          const bulletsTextArray = response.bullets.map(
            (bullet) => bullet.text
          );
          setPack({
            ...response,
            bullets: bulletsTextArray,
          });
        })
        .catch(() => {
          setLoading(false);
          alert(
            "Looks like there's a problem. Reload the page and if this happens again, please come back later."
          );
        });
    } else {
      setPack({
        name: "",
        price: "",
        months: "",
        star: false,
        bullets: [""],
      });
    }
  }, [id, setLoading]);

  const newPackage = () => {
    setLoading(true);

    // Filter out empty values from pack.bullets
    const nonEmptyBullets = pack.bullets.filter(
      (bullet) => bullet.trim() !== ""
    );

    // Check if all bullets are empty
    if (nonEmptyBullets.length === 0) {
      setLoading(false);
      alert("Make sure you've filled all fields correctly");
      return;
    }

    setPack({ ...pack, bullets: nonEmptyBullets });

    post("packages", { ...pack, bullets: nonEmptyBullets })
      .then(() => {
        setLoading(false);
        navigate(-1);
      })
      .catch(() => {
        setLoading(false);
        alert(
          "Looks like there's a problem. Reload the page and if this happens again, please come back later."
        );
      });
  };

  const updatePackage = () => {
    setLoading(true);

    // Filter out empty values from pack.bullets
    const nonEmptyBullets = pack.bullets.filter(
      (bullet) => bullet.trim() !== ""
    );

    if (pack.name === "" || pack.price === "" || pack.months === "") {
      setLoading(false);
      alert("Make sure you've filled all fields correctly");
      return;
    }

    put(`packages/${id}`, { ...pack, bullets: nonEmptyBullets })
      .then(() => {
        setLoading(false);
        navigate(-1);
      })
      .catch(() => {
        setLoading(false);
        alert(
          "Looks like there's a problem. Reload the page and if this happens again, please come back later."
        );
      });
  };

  const deletePackage = () => {
    remove(`packages/${id}`)
      .then(() => {
        setLoading(false);
        navigate(-1);
      })
      .catch((error) => {
        setLoading(false);
        alert(
          "Looks like there's a problem. Reload the page and if this happens again, please come back later."
        );
      });
  };

  return (
    <Container>
      <div className={styles.container}>
        <img className={styles.img} src={packages} alt="packages" />
        <input
          placeholder="Package name"
          className={styles.name}
          value={pack.name}
          onChange={(e) => setPack({ ...pack, name: e.target.value })}
        />
        <p className={styles.length}>7/12</p>
        <div className={styles.details}>
          <div className={styles.inputContainer}>
            <img src={price} alt="price" />
            <input
              className={styles.input}
              type="number"
              value={pack.price}
              onChange={(e) => setPack({ ...pack, price: e.target.value })}
            />
            <p className={styles.p}>JD</p>
          </div>
          <div className={styles.inputContainer}>
            <img src={months} alt="months" />
            <input
              className={styles.input}
              type="number"
              value={pack.months}
              onChange={(e) => setPack({ ...pack, months: e.target.value })}
            />
            <p className={styles.p}>Months</p>
          </div>
          <div className={styles.inputContainer}>
            <img src={star} alt="star" />
            <p className={styles.p}>Star</p>
            <input
              type="checkbox"
              className={styles.checkbox}
              checked={pack.star}
              onChange={() => setPack({ ...pack, star: !pack.star })}
            />
          </div>
        </div>
        <div className={styles.bulletsContainer}>
          <p className={styles.p} style={{ marginBottom: 10 }}>
            Package Description
          </p>
          {pack.bullets &&
            pack.bullets.map((bullet, index) => (
              <div className={styles.bullet} key={index}>
                <li />
                <RegularInput
                  value={bullet}
                  onChange={(e) => {
                    const updatedBullets = [...pack.bullets];
                    updatedBullets[index] = e.target.value;
                    setPack({ ...pack, bullets: updatedBullets });
                  }}
                  placeholder="Description Point"
                />
                <div
                  className={styles.removeField}
                  onClick={() => {
                    const updatedBullets = [...pack.bullets];
                    updatedBullets.splice(index, 1); // Remove the element at the current index
                    setPack({ ...pack, bullets: updatedBullets });
                  }}
                />
              </div>
            ))}
          <div
            className={styles.addField}
            onClick={() => setPack({ ...pack, bullets: [...pack.bullets, ""] })}
          />
        </div>
        <button
          className={`${styles.button} ${styles.submit}`}
          onClick={id ? updatePackage : newPackage}
        >
          Submit
        </button>
        <button
          className={`${styles.button} ${styles.delete}`}
          onClick={() => (id ? deletePackage() : navigate(-1))}
        >
          {id ? "Delete" : "Cancel"}
        </button>
      </div>
    </Container>
  );
};

export default Package;
