import React from "react";
import styles from "./Container.module.css";
import backArrow from "../../assets/Header/backArrow.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
const AccountHome = ({ children, back = true, columns = 1 }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.back} onClick={() => back && navigate(-1)}>
        {back && (
          <>
            <img src={backArrow} alt="back" />
            <p>Back</p>
          </>
        )}
      </div>
      <div className={columns === 2 ? styles.columns : styles.content}>
        {children}
      </div>
      {back && <Footer />}
    </div>
  );
};

export default AccountHome;
