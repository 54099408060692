import React, { useContext, useEffect, useState } from "react";
import styles from "./WhatsappLink.module.css";
import Container from "../../Components/Account/Container";
import whatsapp from "../../assets/Community/whatsappLarge.svg";
import RegularInput from "../../Components/TextComponents/RegularInput";
import { useNavigate } from "react-router-dom";
import { get, put } from "../../fetch";
import Context from "../../Context";

const WhatsappLink = () => {
  const navigate = useNavigate();
  const [whatsappLink, setWhatsappLink] = useState(
    "https://chat.whatsapp.com/IwdPk92_d5qo8921dh2783hfDw2"
  );
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get("configs")
      .then((response) => {
        setLoading(false);
        setWhatsappLink(response.whatsapp_group_url);
      })
      .catch((error) => {
        setLoading(false);
        alert("Looks like there's a problem. Reload the page and if this happens again, please come back later.");
      });
  }, [setLoading]);

  const update = () => {
    put("configs/whatsapp_group_url", { config_value: whatsappLink })
      .then(() => navigate(-1))
      .catch(() =>
        alert(
          "Looks like there's a problem. Reload the page and if this happens again, please come back later."
        )
      );
  };

  return (
    <Container>
      <div className={styles.container}>
        <img className={styles.img} src={whatsapp} alt="whatsapp" />
        <h2 className={styles.title}>WhatsApp Link</h2>
        <RegularInput
          value={whatsappLink}
          onChange={(e) => setWhatsappLink(e.target.value)}
          type="text"
          placeholder="Enter whatsapp link..."
        />
        <button className={styles.button} onClick={update}>
          Submit
        </button>
      </div>
    </Container>
  );
};

export default WhatsappLink;
