import { useContext, useEffect, useState, CSSProperties } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Header from "./Components/Header";
import styles from "./App.module.css";
import Footer from "./Components/Footer";
import AccountHome from "./Pages/Account/AccountHome";
import EditProfile from "./Pages/Account/EditProfile";
import Payment from "./Pages/Account/Payment";
import CommunityHome from "./Pages/Community/CommunityHome";
import CommunityMembers from "./Pages/Community/CommunityMembers";
import Member from "./Pages/Community/Member";
import FreeMonths from "./Pages/Community/FreeMonths";
import CommunitySettings from "./Pages/Community/CommunitySettings";
import WhatsappLink from "./Pages/Community/WhatsappLink";
import Packages from "./Pages/Community/Packages";
import Package from "./Pages/Community/Package";
import DashboardHome from "./Pages/Dashboard/DashboardHome";
import Transactions from "./Pages/Dashboard/Transactions";
import Transaction from "./Pages/Dashboard/Transaction";
import Application from "./Pages/Dashboard/Application";
import LoginPage from "./Pages/Auth/LoginPage";
import ScrollToTop from "./Components/ScrollToTop";
import ResetPassword from "./Pages/Auth/ResetPassword";

import Context from "./Context";
import { PropagateLoader } from "react-spinners";
import NewSpecialMember from "./Pages/Community/NewSpecialMember";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function App() {
  const { user, loading } = useContext(Context);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoad(true), 500);
  }, []);

  if (load)
    return (
      <>
        {loading && (
          <div className={styles.loaderContainer}>
            <PropagateLoader
              color="#437689"
              loading={true}
              cssOverride={override}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <ScrollToTop />
        <Header />
        <div className={styles.appContainer}>
          {user.full_name ? (
            <Routes>
              {/* <Route path="*" element={<Navigate to="/" />} /> */}
              <Route path="/" element={<HomePage />} />

              <Route path="/account" element={<AccountHome />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/edit-special-member/:id" element={<EditProfile special />} />
              <Route path="/payment" element={<Payment />} />

              <Route path="/community" element={<CommunityHome />} />
              <Route path="/community/special-members" element={<CommunityMembers special />} />
              <Route path="/community/new-special-member" element={<NewSpecialMember />} />
              <Route path="/community/members" element={<CommunityMembers />} />
              <Route path="/community/member/:id" element={<Member />} />
              <Route
                path="/community/free-months/:id"
                element={<FreeMonths />}
              />
              <Route
                path="/community/settings"
                element={<CommunitySettings />}
              />
              {/* <Route path="/community/applications" element={<Navigate to="/dashboard/applications" />} /> */}
              <Route
                path="/community/applications"
                element={<Transactions data={"applications"} />}
              />
              <Route
                path="/community/settings/modify-packages"
                element={<Packages />}
              />
              <Route
                path="/community/settings/modify-packages/new"
                element={<Package />}
              />
              <Route
                path="/community/settings/modify-packages/:id"
                element={<Package />}
              />
              <Route
                path="/community/settings/whatsapp"
                element={<WhatsappLink />}
              />

              <Route path="/dashboard" element={<DashboardHome />} />
              <Route
                path="/dashboard/transactions"
                element={<Transactions data={"transactions"} />}
              />
              <Route
                path="/dashboard/transactions/:id"
                element={<Transaction />}
              />
              <Route
                path="/dashboard/applications"
                element={<Transactions data={"applications"} />}
              />
              <Route
                path="/dashboard/applications/:id"
                element={<Application />}
              />
              <Route path="/change-password" element={<ResetPassword />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          )}
        </div>
        <Footer />
      </>
    );
  else return <div></div>;
}

export default App;
