import React, { useContext, useEffect, useState } from "react";
import styles from "./CommunityMembers.module.css";
import Search from "../../Components/TextComponents/Search";
import MembersCard from "../../Components/Community/MembersCard";
import addMember from "../../assets/Community/add-member.svg";

import { get } from "../../fetch";
import Context from "../../Context";
import { useNavigate } from "react-router-dom";

const CommunityMembers = ({ special = false }) => {
  const navigate = useNavigate();
  const [shownMembers, setShownMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    if (special) {
      get("special-members")
        .then((response) => {
          setLoading(false);
          setMembers(response);
          setShownMembers(response);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      get("admin-members")
        .then((response) => {
          setLoading(false);
          setMembers(response);
          setShownMembers(response);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [special, setLoading]);

  const onSearch = (option, searchText) => {
    const filteredMembers = members.filter((member) => {
      const isAllOption = option.toLowerCase() === "all";
      const matchesIndustry = isAllOption || (member.industry && member.industry.toLowerCase() === option.toLowerCase());
      const matchesSearchText = member.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
        (member.industry && member.industry.toLowerCase().includes(searchText.toLowerCase())) ||
        (member.bio && member.bio.toLowerCase().includes(searchText.toLowerCase()));
      
      return matchesIndustry && matchesSearchText;
    });
  
    setShownMembers(filteredMembers);
  };
  

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          {special ? "Special Members" : "Founders Community Members"}
        </h2>
        <Search onSearch={onSearch} />
        <p
          className={styles.resultsN}
        >{`--- (${shownMembers.length}) results ---`}</p>
        {shownMembers.map((member) => (
          <MembersCard key={member.id} member={member} />
        ))}
      </div>
      {special && (
        <img
          onClick={() => navigate("/community/new-special-member")}
          className={styles.addMember}
          src={addMember}
          alt="add member"
        />
      )}
    </div>
  );
};

export default CommunityMembers;
