import React, { useContext, useEffect, useState } from "react";
import styles from "./FreeMonths.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../Components/Account/Container";
import blue from "../../assets/Community/blue-tick.svg";
import RegularInput from "../../Components/TextComponents/RegularInput";
import { get, put } from "../../fetch";
import Context from "../../Context";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";

const FreeMonths = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [member, setMember] = useState({});
  const [months, setMonths] = useState("");
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get(`members/${id}`)
      .then((response) => {
        setMember(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id, setLoading]);

  const freeMonths = () => {
    setLoading(true);
    put(`free-months/${id}`, { number_of_months: parseInt(months) })
      .then(() => {
        setLoading(false);
        navigate(-1);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Container>
      <div className={styles.container}>
        <img
          src={member.profile_pic ? member.profile_pic.original : applicationIcon}
          className={styles.img}
          alt="profile pic"
        />
        <div className={styles.name}>
          {member.full_name}
          {member.verified ? (
            <img className={styles.verified} src={blue} alt="verified" />
          ) : (
            ""
          )}
        </div>
        <p className={styles.email}>{member.email}</p>
        <RegularInput
          value={months}
          onChange={(e) => setMonths(e.target.value)}
          type="number"
          placeholder="Enter number of months"
        />
        <button className={styles.button} onClick={freeMonths}>
          Submit
        </button>
      </div>
    </Container>
  );
};

export default FreeMonths;
