import React, { useContext } from "react";
import styles from "./Header.module.css";
import HeaderLogo from "../assets/Header/HeaderLogo.svg";
import Context from "../Context";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { logout } = useContext(Context);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <img
        src={HeaderLogo}
        alt="logo"
        className={styles.logo}
        onClick={() => navigate("/")}
      />
      <div className={styles.logout} onClick={logout} />
    </div>
  );
};

export default Header;
