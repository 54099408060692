import React from "react";
import styles from "./AccountHome.module.css";
import verified from "../../assets/Community/blue-tick.svg";
import Container from "../../Components/Account/Container";
import transactionsIcon from "../../assets/Account/transactions.svg";
import paymentIcon from "../../assets/Account/payment.svg";
import passwordIcon from "../../assets/Account/password.svg";
import logoutIcon from "../../assets/Account/logout.svg";
import { useNavigate } from "react-router-dom";
import UserInfoItem from "../../Components/Lists/UserInfoItem";
import { useContext } from "react";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";

import Context from "../../Context";

const AccountHome = () => {
  const navigate = useNavigate();
  const { logout, user } = useContext(Context);
  const items = [
    {
      id: 1,
      text: "Transactions History",
      img: transactionsIcon,
      to: "/dashboard/transactions",
      unclickable: false,
    },
    {
      id: 2,
      text: "Payment Details",
      img: paymentIcon,
      to: "/payment",
      unclickable: false,
    },
    {
      id: 3,
      text: "Change Password",
      img: passwordIcon,
      to: "/change-password",
    },
    { id: 4, text: "Logout", img: logoutIcon, to: "logout" },
  ];

  return (
    <Container>
      <div className={styles.profilePicture}>
        <img
          src={
            user.profile_pic
              ? user.profile_pic.original
              : applicationIcon
          }
          style={{
            borderRadius: "50%",
            height: "100%",
            width: "100%",
            pointerEvents: "none",
            objectFit: "cover",
          }}
          alt="profile pic"
        />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          <p>{user.full_name}</p>
          <img src={verified} alt="verified" />
        </div>
        <p className={styles.email}>{user.email}</p>
      </div>
      <button
        className={styles.editProfile}
        onClick={() => navigate("/edit-profile")}
      >
        Edit Profile
      </button>
      {items.map((item) => (
        <UserInfoItem onLogout={logout} key={item.id} item={item} />
      ))}
    </Container>
  );
};

export default AccountHome;
